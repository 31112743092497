import * as React from "react";

function SvgIconLock(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12.5 15.667" {...props}>
      <path
        fill="#6e2af5"
        d="M10.937 15.667H1.562A1.53 1.53 0 010 14.175V6.714a1.53 1.53 0 011.562-1.492v-.746a4.349 4.349 0 011.37-3.168A4.773 4.773 0 016.25 0a4.773 4.773 0 013.317 1.308 4.349 4.349 0 011.37 3.168v.746A1.531 1.531 0 0112.5 6.714v7.46a1.53 1.53 0 01-1.563 1.493zM6.25 8.952a1.494 1.494 0 100 2.984 1.494 1.494 0 100-2.984zm0-7.46a2.99 2.99 0 00-3.125 2.984v.746h6.25v-.746A2.99 2.99 0 006.25 1.492z"
      />
    </svg>
  );
}

export default SvgIconLock;
