import * as React from "react";

function SvgDate(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
      <circle cx={15} cy={15} r={15} fill="rgba(0,31,92,0.19)" />
      <path
        fill="#fff"
        d="M12.561 22C9.866 22 8.5 20.8 8.5 18.437v-5.428h13v5.428C21.5 20.8 20.132 22 17.433 22zm4.811-3.433a.608.608 0 00.6.6.6.6 0 100-1.19h-.005a.6.6 0 00-.596.59zm-2.969 0a.6.6 0 10.6-.6H15a.607.607 0 00-.6.6zm-2.794-.43a.587.587 0 00-.175.43.592.592 0 10.175-.43zm5.77-2.111a.6.6 0 10.595-.595.589.589 0 00-.596.596zm-2.976 0a.6.6 0 10.6-.6H15a.607.607 0 00-.6.601zm-2.97 0a.6.6 0 001.015.423.583.583 0 00.175-.429.592.592 0 00-.589-.595h-.006a.6.6 0 00-.596.602zm10.061-3.017H20.5v-.278a3.025 3.025 0 00-.3-1.446 1.885 1.885 0 00-.955-.845 3.353 3.353 0 00-.989-.246v.513a.52.52 0 01-1.04 0v-.55H12.8v-1h4.41v-.65a.52.52 0 011.04-.007v.7a5.118 5.118 0 01.995.2v-.008a.592.592 0 00.062.023l.033.011.045.016c.062.019.118.042.179.066l.031.013a.859.859 0 01.083.034l.026.012a2.172 2.172 0 01.245.122l.038.021a.665.665 0 01.061.037l.04.025a3.056 3.056 0 01.52.408l.005.009a2.079 2.079 0 01.165.179v.009a3.632 3.632 0 01.714 2.361v.27zm-11.994 0h-1v-.268a3.635 3.635 0 01.716-2.36l.01-.009c.03-.036.061-.07.094-.106l.066-.073.014-.009a2.924 2.924 0 01.515-.408l.037-.023c.023-.014.045-.028.068-.04l.123-.069.078-.037.079-.036.055-.023.055-.023c.083-.033.151-.059.215-.078a.367.367 0 01.075-.028.217.217 0 01.034-.013l.029-.01a5.11 5.11 0 01.986-.195V8.51a.52.52 0 011.04 0v2.2a.52.52 0 01-1.04 0v-.51a3.513 3.513 0 00-.994.246 2.19 2.19 0 00-1.249 2.292v.27z"
      />
    </svg>
  );
}

export default SvgDate;
