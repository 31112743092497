import * as React from "react";

function SvgUser(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
      <g transform="translate(-290 -1169)">
        <circle
          cx={15}
          cy={15}
          r={15}
          fill="rgba(0,31,92,0.19)"
          transform="translate(290 1169)"
        />
        <path
          fill="#fff"
          d="M304.068 1191a4.329 4.329 0 00-.553-.037 10.868 10.868 0 01-1.606-.205 2.948 2.948 0 01-2.218-1.246 1.749 1.749 0 01-.006-1.576 2.909 2.909 0 012.224-1.245 11.594 11.594 0 011.612-.206 18.173 18.173 0 012.979 0 11.884 11.884 0 011.6.206 2.982 2.982 0 012.218 1.246 1.751 1.751 0 010 1.55 2.859 2.859 0 01-2.23 1.246 11.317 11.317 0 01-1.606.212 13.446 13.446 0 01-1.5.056zm-2.676-10.22A3.7 3.7 0 01305 1177a3.7 3.7 0 013.61 3.78 3.7 3.7 0 01-3.61 3.781 3.7 3.7 0 01-3.608-3.781z"
        />
      </g>
    </svg>
  );
}

export default SvgUser;
